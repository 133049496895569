import { IconMapPin } from "@tabler/icons";
import Image from "next/image";
import { DisplayDomainBadges } from "../../utils/misc";

export default function PastEventHPCard({
  eventName,
  img,
  town,
  domains,
}: {
  eventName: string;
  img: string;
  town: string;
  domains: string[];
}) {
  return (
    <div className="mosaic-tile relative cursor-pointer">
      <Image
        src={img}
        alt={eventName}
        objectFit="cover"
        className="z-0 rounded-md"
        layout="fill"
        sizes="(max-width: 420px)  384px"
      />

      <div className="absolute left-0 top-0 p-4">
        <p className="text-lg font-medium text-white">
          <DisplayDomainBadges domains={domains} size="md" slice={1} />
        </p>
      </div>
      <div className="absolute right-0 top-0 mr-2 mt-3 flex max-w-sm items-center rounded-xl bg-gray-600 bg-opacity-50 px-2 py-1">
        <div className="icon-container mr-1 h-[20px] w-[20px]">
          <IconMapPin size={20} className="text-yellow-400 " />
        </div>

        <div className="text-md mt-[2px] font-semibold text-white">{town}</div>
      </div>

      <div className="absolute bottom-0 left-0 w-full rounded-b-md bg-black bg-opacity-50 py-2">
        <p className="text-center font-semibold text-white">{eventName}</p>
      </div>
      <style jsx>{`
        .mosaic-tile {
          height: 228px;
          width: 384px;
        }
      `}</style>
    </div>
  );
}
