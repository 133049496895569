import { IconMapPin } from "@tabler/icons";
import { ThemeContext } from "~/contexts/ThemeContext";
import Image from "next/image";
import { useContext } from "react";
import { DisplayPartnerBadge, displayThemeTitle } from "~/utils/misc";
import Link from "next/link";

export default function SmallHostCard({
  name,
  username,
  img,
  town,
  isPartner,
}: {
  name: string;
  username: string;
  img: string | null;
  town: string;
  isPartner?: boolean;
}) {
  const { secondaryColor, fontType } = useContext(ThemeContext);
  return (
    <Link href={"/hosts/" + username}>
      <div className="mosaic-tile relative cursor-pointer">
        <Image
          src={img ? img : "/placeholders/host1.jpeg"}
          alt={name}
          objectFit="cover"
          className="z-0 rounded-md"
          layout="fill"
          sizes="384px"
        />
        <div className="absolute left-0 top-0 ml-2 mt-1 rounded-md  p-1 ">
          {isPartner && <DisplayPartnerBadge size={50} />}
        </div>
        <div className="absolute right-0 top-0 mr-2 mt-3 flex max-w-sm items-center rounded-xl bg-gray-600 bg-opacity-50 px-2 py-1">
          <div className="icon-container mr-1 h-[20px] w-[20px]">
            <IconMapPin size={20} className="text-yellow-400 " />
          </div>
          <div className="text-md mt-[2px] font-semibold text-white">
            {town}
          </div>
        </div>
        <div
          className="absolute bottom-0 left-0 h-11 w-full rounded-b-md py-2"
          style={{ backgroundColor: secondaryColor as string }}
        >
          <p className="text-center  ">
            {displayThemeTitle({
              fontType: fontType as string,
              title: name,
              sizeDelta: -5,
            })}
          </p>
        </div>
        <style jsx>{`
          .mosaic-tile {
            height: 228px;
            width: 384px;
          }
        `}</style>
      </div>
    </Link>
  );
}
