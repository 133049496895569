import { TextInput, TextInputProps } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { IconSearch } from "@tabler/icons";
import { useEffect } from "react";

interface DataItem {
  type: "Host" | "Artist" | "Troupe" | "Event";
  id: string;
  name: string;
  city: string | null;
  username: string;
  dateStart: string | null;
}

interface HomePageSearchBarProps extends TextInputProps {
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  setSearchResult: (result: DataItem[]) => void;
  setIsLoading: (isLoading: boolean) => void;
}

export default function HomePageSearchBar(props: HomePageSearchBarProps) {
  const {
    searchTerm,
    setSearchTerm,
    setSearchResult,
    setIsLoading,
    ...textInputProps
  } = props;

  const [debounced] = useDebouncedValue(searchTerm, 400);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchResult([]);
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (debounced && debounced.length >= 3) {
        setIsLoading(true); // Start loading
        try {
          const response = await fetch(
            `/api/global-search?search=${encodeURIComponent(debounced)}`
          );
          if (!response.ok) {
            throw new Error(`Error: ${response.status}`);
          }
          const data = await response.json();
          setSearchResult(data as DataItem[]);
        } catch (error) {
          console.error("Failed to fetch data:", error);
        } finally {
          setIsLoading(false); // Stop loading regardless of success or failure
        }
      } else {
        setSearchResult([]); // Clear results if less than 3 characters
      }
    };

    fetchData();
  }, [debounced, setIsLoading, setSearchResult]);

  return (
    <TextInput
      icon={<IconSearch size="1.1rem" stroke={1.5} />}
      radius="xl"
      size="md"
      placeholder="Recherchez un évènement, hôte, artiste, troupe"
      rightSectionWidth={42}
      onChange={handleChange}
      {...textInputProps}
    />
  );
}
