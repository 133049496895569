import { EventTile, Host } from "@prisma/client";
import { IconHome, IconPhoto, IconStars } from "@tabler/icons";
import DisplayHPSearchResult from "~/components/HomePage/DisplayHPSearchResult";
import PastEventHPCard from "~/components/HomePage/PastEventHPCard";
import SmallTroupeCard from "~/components/Card/Troupe/SmallTroupeCard";
import HomePageMarqueeSlider from "~/components/Misc/HomePageMarqueeSlider";
import HomePageSearchBar from "~/components/SearchBar/HomePage/HomePageSearchBar";
import { ThemeContext } from "~/contexts/ThemeContext";
import Image from "next/image";
import Link from "next/link";
import { useContext, useEffect, useState } from "react";
import SmallHostCard from "~/components/Card/Host/SmallHostCard";
import { InferGetStaticPropsType } from "next";
import { createServerSideHelpers } from "@trpc/react-query/server";
import { appRouter } from "~/server/api/root";
import { createContext } from "~/server/api/routers/context";
import _ from "lodash";
import { useAtom } from "jotai";
import { loadingAtom } from "~/contexts/LoadingContext";
import { displayThemeTitle } from "~/utils/misc";

interface DataItem {
  type: "Host" | "Artist" | "Troupe" | "Event";
  id: string;
  name: string;
  city: string | null;
  username: string;
  dateStart: string | null;
}

export default function HomePage(
  props: InferGetStaticPropsType<typeof getStaticProps>,
) {
  const [searchTerm, setSearchTerm] = useState("");
  const { fontType } = useContext(ThemeContext);
  const { events, partners } = props;
  const [searchResult, setSearchResult] = useState<DataItem[] | []>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [, setLoading] = useAtom(loadingAtom);

  useEffect(() => {
    // Deactivate the loading effect when the component mounts
    setLoading(false);
  }, [setLoading]);

  const displayTitle = (title: string) => {
    switch (fontType) {
      case "font-classic":
        return (
          <div>
            <div className="text-center font-classic text-9xl">{title}</div>
            <div className="-mt-5 text-center font-classic text-4xl">
              La plateforme des scènes ouvertes
            </div>
          </div>
        );
      case "font-disco":
        return (
          <div>
            <div className="text-center font-disco text-8xl">{title}</div>
            <div className="text-center font-disco text-3xl">
              La plateforme des scènes ouvertes
            </div>
          </div>
        );
      case "font-edm":
        return (
          <div>
            <div className="text-center font-edm text-8xl">{title}</div>
            <div className="text-center font-edm text-3xl">
              La plateforme des scènes ouvertes
            </div>
          </div>
        );
      case "font-jazz":
        return (
          <div>
            <div className="text-center font-jazz text-8xl">{title}</div>
            <div className="text-center font-jazz text-3xl">
              La plateforme des scènes ouvertes
            </div>
          </div>
        );
      case "font-rock":
        return (
          <div>
            <div className="text-center font-rock text-6xl">{title}</div>
            <div className="mt-6 text-center font-rock text-2xl">
              La plateforme des scènes ouvertes
            </div>
          </div>
        );
      case "font-rap":
        return (
          <div>
            <div className="text-center font-rap text-8xl">{title}</div>
            <div className="text-center font-rap text-3xl">
              La plateforme des scènes ouvertes
            </div>
          </div>
        );
      default:
        return (
          <div>
            <div className="text-center font-classic text-6xl">{title}</div>
            <div className="-mt-5 text-center font-classic text-2xl">
              La plateforme des scènes ouvertes
            </div>
          </div>
        );
    }
  };

  return (
    <div>
      <div className="-mx-[16px] -mt-4">
        <HomePageMarqueeSlider />
      </div>
      <div className="flex justify-center font-classic ">
        {displayTitle("Atrio")}
      </div>

      <div className="mx-auto mt-4 max-w-xl">
        <HomePageSearchBar
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          setSearchResult={setSearchResult}
          setIsLoading={setIsLoading}
        />
      </div>
      {searchTerm.length > 0 ? (
        <div className="mt-5">
          <div className="flex justify-start text-2xl">
            Résultats de recherche pour &apos;{searchTerm}&apos;
          </div>
          <div className="mt-5">
            <DisplayHPSearchResult data={searchResult} isLoading={isLoading} />
          </div>
        </div>
      ) : (
        <div className="mt-16 flex flex-wrap justify-around gap-6">
          <div>
            <div>
              <Link href="/mosaic">
                <div className="mx-auto flex w-fit cursor-pointer items-center justify-center gap-5 rounded-md px-4 py-1 hover:bg-slate-500">
                  <div>
                    <Image
                      src="/polaroid.png"
                      width={60}
                      height={60}
                      alt="hosts"
                    />
                  </div>
                  {displayThemeTitle({
                    fontType: fontType as string,
                    title: "Souvenirs",
                    sizeDelta: -2,
                  })}
                </div>
              </Link>
              <div className="mx-auto mt-1 flex  max-w-[14rem] text-center text-sm italic text-gray-200">
                Retrouvez les évènements passés de la plateforme
              </div>
            </div>
            <div className="mt-4 flex flex-col gap-4">
              {events.length > 0 &&
                events.map((tile) => (
                  <div key={tile.id}>
                    <PastEventHPCard
                      domains={tile.domains}
                      eventName={tile.eventName}
                      img={tile.img}
                      town={tile.city}
                    />
                  </div>
                ))}

              <Link href="/mosaic">
                <div className="flex cursor-pointer items-center justify-center gap-2 text-yellow-500 hover:text-yellow-300 hover:underline ">
                  <IconPhoto />
                  <span className="text-xl italic ">
                    Voir tous les Souvenirs
                  </span>
                </div>
              </Link>
            </div>
          </div>

          <div>
            <div>
              <Link href="/hosts">
                <div className="mx-auto flex w-fit cursor-pointer items-center justify-center gap-5 rounded-md px-4 py-1 hover:bg-slate-500">
                  <div>
                    <Image
                      src={
                        "/host-icons/" + fontType?.replace("font-", "") + ".png"
                      }
                      width={60}
                      height={60}
                      alt="hosts"
                    />
                  </div>
                  {displayThemeTitle({
                    fontType: fontType as string,
                    title: "Hôtes",
                    sizeDelta: -2,
                  })}
                </div>
              </Link>
              <div className="mx-auto mt-1 flex  max-w-[14rem] text-center text-sm italic text-gray-200">
                Montez sur scène chez nos hôtes partenaires
              </div>
            </div>
            <div className="mt-4 flex flex-col gap-4">
              {partners.length > 0 &&
                partners.map((tile) => (
                  <div key={tile.id}>
                    <SmallHostCard
                      name={tile.name}
                      username={tile.username}
                      img={tile.image}
                      town={tile.city}
                    />
                  </div>
                ))}
              <Link href="/hosts">
                <div className="flex cursor-pointer items-center justify-center gap-2 text-yellow-500 hover:text-yellow-300 hover:underline ">
                  <IconHome />
                  <span className="text-xl italic ">Voir tous les Hôtes</span>
                </div>
              </Link>
            </div>
          </div>
          <div>
            <div>
              <Link href={"/troupes"}>
                <div className="mx-auto flex w-fit cursor-pointer items-center justify-center gap-5 rounded-md px-4 py-1 hover:bg-slate-500">
                  <div>
                    <Image
                      src="/ribbon.png"
                      width={60}
                      height={60}
                      alt="line-up"
                    />
                  </div>
                  {displayThemeTitle({
                    fontType: fontType as string,
                    title: "Troupes",
                    sizeDelta: -2,
                  })}
                </div>
              </Link>
              <div className="mx-auto mt-1 flex  max-w-[14rem] text-center text-sm italic text-gray-200">
                Rejoignez une troupe et faites de nouvelles rencontres
              </div>
            </div>
            <div className="mt-4 flex flex-col gap-4">
              <SmallTroupeCard />
              <SmallTroupeCard />
              <SmallTroupeCard />
              <Link href="/recruitment/troupe">
                <div className="flex cursor-pointer items-center justify-center gap-2 text-yellow-500 hover:text-yellow-300 hover:underline ">
                  <IconStars />
                  <span className="text-xl italic ">
                    Voir toutes les Troupes
                  </span>
                </div>
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export async function getStaticProps() {
  const helpers = createServerSideHelpers({
    router: appRouter,
    ctx: createContext(),
  });

  await helpers.eventTile.getXLatestTiles.prefetch(3);
  await helpers.host.getXPartners.prefetch(3);

  return {
    props: {
      events: helpers.dehydrate().queries.at(0)?.state.data as EventTile[],
      partners: helpers.dehydrate().queries.at(1)?.state.data as Host[],
      revalidate: 1,
    },
  };
}
