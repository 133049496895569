import { ActionIcon } from "@mantine/core";
import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandTiktok,
  IconBrandYoutube,
} from "@tabler/icons";
import { ThemeContext } from "~/contexts/ThemeContext";
import Image from "next/image";
import { useContext } from "react";
import { DisplayDomainBadges } from "~/utils/misc";

export default function SmallTroupeCard() {
  const { secondaryColor } = useContext(ThemeContext);

  return (
    <div
      className="flex min-h-[228px] max-w-sm cursor-pointer flex-col justify-between overflow-hidden rounded text-white shadow-lg"
      style={{ backgroundColor: secondaryColor as string }}
    >
      <div className="flex justify-between gap-2 px-4 py-4">
        <div>
          <div className="flex items-center gap-3">
            <div className="text-2xl font-bold">Team Rocket</div>
          </div>
          <span className="max-w-xs text-sm italic">
            De retour pour vous jouer un mauvais tour
          </span>
          <div className="mt-2">
            <DisplayDomainBadges domains={["stand-up"]} size="xs" slice={1} />
          </div>
        </div>
        <div className="">
          <Image
            src="/placeholders/team-logo-1.png"
            alt="logo"
            width={70}
            height={70}
          />
        </div>
      </div>

      <div className="flex-grow  px-6 py-2">
        <div className="flex justify-between">
          <div className="text-center">
            <p className="text-xl font-semibold">42</p>
            <p className="text-xs">membres</p>
          </div>
          <div className="text-center">
            <p className="text-xl font-semibold">23</p>
            <p className="text-xs">évènements</p>
          </div>
          <div className="text-center">
            <p className="text-xl font-semibold">3</p>
            <p className="text-xs">prix remportés</p>
          </div>
        </div>
      </div>

      <div className="flex h-[44px] items-center justify-between bg-slate-500 px-6">
        <ActionIcon>
          <IconBrandFacebook size={25} />
        </ActionIcon>
        <ActionIcon>
          <IconBrandInstagram size={25} />
        </ActionIcon>
        <ActionIcon>
          <IconBrandTiktok size={25} />
        </ActionIcon>
        <ActionIcon>
          <IconBrandYoutube size={25} />
        </ActionIcon>
      </div>
    </div>
  );
}
