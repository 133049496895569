import { Avatar, createStyles, Loader } from "@mantine/core";
import {
  IconCalendar,
  IconHome,
  IconMapPin,
  IconMicrophone2,
  IconStars,
} from "@tabler/icons";
import { ThemeContext } from "~/contexts/ThemeContext";
import React, { useContext } from "react";

interface DataItem {
  type: "Host" | "Artist" | "Troupe" | "Event";
  id: string;
  name: string;
  city: string | null;
  username: string;
  dateStart: string | null;
}

interface DisplayHPSearchResultProps {
  data: DataItem[];
  isLoading: boolean;
}

interface RowResultProps {
  imgSrc: string;
  title: string;
}

interface IconRowResultProps {
  icon: React.ReactNode;
  mainText: string | React.ReactNode;
  secondaryText: string;
}

interface GroupedData {
  [key: string]: DataItem[] | undefined; // Adjusted index signature
  Host?: DataItem[];
  Artist?: DataItem[];
  Troupe?: DataItem[];
  Event?: DataItem[];
}

const RowResult: React.FC<RowResultProps> = ({ imgSrc, title }) => {
  const useStyles = createStyles((theme) => ({
    rowResult: {
      display: "flex",
      alignItems: "center",
      position: "relative",
      gap: "10px",
    },
    line: {
      flexGrow: 1,
      height: "1px",
      backgroundColor: "#384148",
      marginLeft: theme.spacing.sm,
    },
  }));

  const { classes } = useStyles();

  return (
    <div className={classes.rowResult}>
      <img src={imgSrc} width={38} height={38} alt={title} />
      <p className="relative  z-10 text-[24px] font-semibold">{title}</p>
      <div className={classes.line}></div>
    </div>
  );
};

const IconRowResult: React.FC<IconRowResultProps> = ({
  icon,
  mainText,
  secondaryText,
}) => (
  <div className="ml-2 flex cursor-pointer items-center gap-2 rounded-md px-3 py-1 hover:bg-slate-500">
    {icon}
    <span className="text-xl">{mainText}</span>
    <span className="text-base">{secondaryText}</span>
  </div>
);

const DisplayHPSearchResult: React.FC<DisplayHPSearchResultProps> = ({
  data,
  isLoading,
}) => {
  const { fontType } = useContext(ThemeContext);

  const renderIcon = (type: string) => {
    switch (type) {
      case "Host":
        return <IconHome size={20} className="text-yellow-400" />;
      case "Artist":
        return <IconMicrophone2 size={20} className="text-yellow-400" />;
      case "Troupe":
        return <IconStars size={20} className="text-yellow-400" />;
      case "Event":
        return <IconCalendar size={20} className="text-yellow-400" />;
      default:
        return null;
    }
  };

  const extractedType = fontType?.replace("font-", "");

  const getImageSrc = (type: string) => {
    switch (type) {
      case "Event":
        return "/search-icons/events.png";
      case "Troupe":
        return "/search-icons/troupe.png";
      case "Artist":
        return `/artist-icons/${extractedType}.png`;
      case "Host":
        return `/host-icons/${extractedType}.png`;
      default:
        return "";
    }
  };

  const formatDate = (dateString: string | null) => {
    if (!dateString) return "";

    const options: Intl.DateTimeFormatOptions = {
      weekday: "long",
      day: "2-digit",
      month: "long",
    };

    const dateStr = new Date(dateString).toLocaleDateString("fr-FR", options);
    return capitalizeFirstLetter(dateStr);
  };

  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const groupedData = data.reduce<GroupedData>((acc, item) => {
    (acc[item.type] = acc[item.type] || []).push(item);
    return acc;
  }, {});

  const orderedTypes = ["Event", "Artist", "Host", "Troupe"];

  if (isLoading) {
    return (
      <div className="flex justify-center">
        <Loader size={"xl"} />
      </div>
    );
  }

  if (data.length === 0) {
    return <div className="">Aucun résultat</div>;
  }

  return (
    <div className="ml-2 mt-4 flex flex-col gap-3">
      {orderedTypes.map((type) => (
        <React.Fragment key={type}>
          {(groupedData[type]?.length ?? 0) > 0 && (
            <>
              <RowResult imgSrc={getImageSrc(type)} title={type} />
              {groupedData[type]?.map((item: DataItem, index: number) => {
                let mainText = item.name;
                let formattedDate = null;

                if (item.type === "Event") {
                  // For Events, always include the city if available
                  if (item.city) {
                    mainText = `${item.city} • ${mainText}`;
                  }
                  if (item.dateStart) {
                    formattedDate = (
                      <span className="text-base">
                        {" - "}
                        {formatDate(item.dateStart)}
                      </span>
                    );
                  }
                } else if (item.city) {
                  // For other types, include the city only if dateStart is not available
                  mainText = `${item.city} • ${mainText}`;
                }

                const secondaryText = item.username ? `@${item.username}` : "";

                return (
                  <IconRowResult
                    key={index}
                    icon={renderIcon(item.type)}
                    mainText={
                      <span>
                        {mainText}
                        {formattedDate}
                      </span>
                    }
                    secondaryText={secondaryText}
                  />
                );
              })}
            </>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default DisplayHPSearchResult;
