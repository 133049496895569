import { ThemeContext } from "~/contexts/ThemeContext";
import { displayDomainBadge, DisplayDomainBadges } from "~/utils/misc";
import React, { useContext, useState } from "react";
import Marquee from "react-marquee-slider";

const timeslots = [
  {
    hostname: "Les Trois Baudets",
    date: new Date(),
    city: "Paris",
    type: "stage",
  },
  {
    hostname: "Le Réservoir",
    date: new Date(),
    city: "Paris",
    type: "timeslot",
  },
  {
    hostname: "Le Bar à Bulles",
    date: new Date(),
    city: "Paris",
    type: "stage",
  },
  {
    hostname: "La Boule Noire",
    date: new Date(),
    city: "Paris",
    type: "timeslot",
  },
  {
    hostname: "Au Chat Noir",
    date: new Date(),
    city: "Paris",
    type: "stage",
  },
  {
    hostname: "Chez Mama",
    date: new Date(),
    city: "Paris",
    type: "timeslot",
  },
  {
    hostname: "Au Soleil de la Butte",
    date: new Date(),
    city: "Paris",
    type: "stage",
  },
];

export default function HomePageMarqueeSlider() {
  const { secondaryColor } = useContext(ThemeContext);
  const [hoverStates, setHoverStates] = useState(
    new Array(timeslots.length).fill(false)
  );

  const handleMouseEnter = (index: number) => {
    setHoverStates(hoverStates.map((state, i) => (i === index ? true : state)));
  };

  const handleMouseLeave = (index: number) => {
    setHoverStates(
      hoverStates.map((state, i) => (i === index ? false : state))
    );
  };

  return (
    <div style={{ width: "100%", height: "100px" }}>
      <Marquee
        velocity={12}
        direction="rtl" // Define the direction: 'ltr' (left-to-right) or 'rtl' (right-to-left)
        scatterRandomly={false} // Set to false for uniform movement
        resetAfterTries={200} // Number of tries after which to reset the position of children
        onInit={() => {}} // Callback function for when the marquee initializes
        onFinish={() => {}} // Callback function for when the marquee finishes a cycle
      >
        {timeslots.map((slot, index) => (
          <React.Fragment key={`timeslot-${index}`}>
            <div
              style={{ backgroundColor: secondaryColor as string }}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={() => handleMouseLeave(index)}
            >
              <div
                className={`flex gap-2 p-1 items-center px-4 font-semibold hover:bg-slate-400 hover:text-black cursor-pointer`}
                style={{
                  borderLeft: "1px solid #D3D3D3",
                  borderRight: "1px solid #D3D3D3",
                  height: 40,
                }}
              >
                {slot.type === "stage" && (
                  <div
                    className={`flex items-center gap-2 ${
                      hoverStates[index] ? "bg-slate-600" : "bg-slate-500"
                    } text-white px-3 pr-4 p-[2px] rounded-md`}
                  >
                    <img
                      src="/recruitment/microphone2.png"
                      width={25}
                      height={25}
                      alt="créneaux"
                    />
                    <span>Recrutement plateau </span>
                  </div>
                )}
                {slot.type === "timeslot" && (
                  <div
                    className={`flex items-center gap-2 ${
                      hoverStates[index] ? "bg-slate-600" : "bg-slate-500"
                    } text-white px-3 pr-4 p-[2px] rounded-md`}
                  >
                    <img
                      src="/recruitment/timeslot.png"
                      width={25}
                      height={25}
                      alt="créneaux"
                    />
                    <span>Créneau libre</span>
                  </div>
                )}

                <div className="mx-1">
                  {slot.date.toLocaleDateString()} • {slot.city} •{" "}
                  {slot.hostname}
                </div>
                <DisplayDomainBadges
                  domains={["stand-up"]}
                  slice={1}
                  size="md"
                />
              </div>
            </div>
          </React.Fragment>
        ))}
      </Marquee>
    </div>
  );
}
